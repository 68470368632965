/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// import PropTypes from 'prop-types';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { default as Linkm } from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useTheme } from '@mui/material/styles';

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Link } from "react-router-dom";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
// import ToggleColorMode from 'App';
import { ColorModeContext } from 'App.jsx';

// type Props = {
// 	setMode: () => void;
// };
  
function Footer() {
	// const [mode, setMode] = React.useState("");
	const theme = useTheme();
	const colorMode = React.useContext(ColorModeContext);
	const { palette } = theme;
	const { grey, text } = palette;
	const toggleMode = (e) => {
		console.log(theme.palette.mode)
		e.preventDefault();
		e.stopPropagation();
        colorMode.toggleColorMode();
		// setMode(theme.palette.mode)

		//tailwindcss
		// localStorage.theme = theme.palette.mode;
	}
	// console.dir(mode)
	return (
		<MKBox py={1} mt={2} pl={0} sx={{backgroundColor:grey[50], color: text.primary}}>
			<Container>
				<Grid container px={0}>
					<Grid
						item
						xs={12}
						lg={8}
						textAlign={{ xs: "center", lg: "left" }}
						// mr="auto"
						mb={{ xs: 1, lg: 0 }}
					>
						<MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
							Ekambuli High School
						</MKTypography>
						<MKTypography variant="h7">Luanda, Vihiga County, Kenya</MKTypography>
						<MKTypography variant="body2">PO Box 65-50307</MKTypography>
						<MKTypography variant="body2"><LocalPhoneIcon/>+254 722 445053</MKTypography>
						<MKTypography variant="body2"><EmailIcon/>info@ekambulihighschool.sc.ke</MKTypography>
						<Stack
							component="ul"
							direction="row"
							flexWrap="wrap"
							spacing={3}
							justifyContent={{ xs: "center", lg: "flex-start" }}
							pl={0}
							mt={2}
							mb={3}
							sx={{ listStyle: "none", fontSize: "0.6rem", opacity: "0.8", fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
						>
							<MKBox component="li">
								<MKTypography  
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/"
									// target="_blank"
									// rel="noreferrer"
								>
									Home
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography 
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/about-us"
									// target="_blank"
									// rel="noreferrer"
								>
									About Us
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography 
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/terms-of-use"
									// target="_blank"
									// rel="noreferrer"
								>
									Terms of Use
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography 
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									to="/privacy-policy"
									// target="_blank"
									// rel="noreferrer"
								>
									Privacy Policy
								</MKTypography>
							</MKBox>
							{/* <MKBox component="li">
								<MKTypography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									href="/contact-us"
									target="_blank"
									rel="noreferrer"
								>
									Contact Us
								</MKTypography>
							</MKBox> */}
						</Stack>
						<MKTypography variant="button" opacity={0.8}>
							Copyright © {(new Date().getFullYear())} Ekambuli High School.
						</MKTypography>
					</Grid>
					<Grid item xs={12} lg={4} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
						<MKBox fontWeight={500} mb={6}>
							<MKTypography variant="body3">
								Embark on a journey with us where learning leads to excellence, and your skills will always be in high demand.
							</MKTypography>
						</MKBox>
						<MKTypography 
							component={Linkm}
							href="https://x.com/home"
							target="_blank"
							rel="noreferrer"
							variant="body2"
							color="dark"
							opacity={0.5}
							mr={3}
						>
							<TwitterIcon fontSize="small" />
							{/* <i className="fab fa-twitter" /> */}
						</MKTypography>
						<MKTypography 
							component={Linkm}
							href="https://www.facebook.com/"
							target="_blank"
							rel="noreferrer"
							variant="body2"
							color="dark"
							opacity={0.5}
							mr={3}
						>
							<FacebookIcon  fontSize="small" />
							{/* <i className="fab fa-pinterest" /> */}
						</MKTypography>
						<MKTypography 
							component={Linkm}
							href="https://www.youtube.com/"
							target="_blank"
							rel="noreferrer"
							variant="body2"
							opacity={0.5}
							mr={3}
						>
							<YouTubeIcon  fontSize="small" />
							{/* <i className="fab fa-github" /> */}
						</MKTypography>
						<MKTypography sx={{cursor:"pointer", display:"inline"}}
							variant="body2"
							opacity={0.5}
							mr={3}
							onClick={toggleMode}
						>
							{theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small"/> : <Brightness4Icon fontSize="small"/>}
						</MKTypography>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}
// Footer.propTypes = {
//     toggleMode: PropTypes.func
// };
export default Footer;
