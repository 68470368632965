// import userLogout from "../services/userLogout";
// import { useAuth } from "../context/AuthContext";

function errorHandler(error) {
	// const { setAuthState } = useAuth();
	// console.dir(error);
	console.dir(error.response);
	if (!error.response) return console.log(error);

	const { status, data } = error.response;

	console.dir(status);
	if(status===401) {
		// setAuthState(userLogout);
		localStorage.removeItem("loggedUser");
		return {};
	}

	if ([401, 403, 404, 422, 500].includes(status)) {
		// console.log(error.response);
		throw data.errors.body?.[0];
	}
}

export default errorHandler;
