/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/volleyball-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
import {useLocation} from 'react-router-dom';

const AchievementsPg = (props) => {
	const loc = useLocation();
	console.dir(props)
	return (
		<>
            <Helmet>
				<meta name="description" content="Achievements." />
                <link rel="canonical" href={loc.pathname} />
            </Helmet>
			<DefaultNavbar
				routes={routes}
				sticky
				relative
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
					color:"#fff",
					fontSize:"2rem",
					fontFamily:"cursive",
				}}
			>
				Everyone Achieves
			</MKBox>
			<Box p={2} sx={{  maxWidth: '100%', flexGrow: 1, mb:"10px" }}>
					<Grid container spacing={2} >
						<Grid item md={5} mt={-4} >
							<MKTypography variant="h3" sx={{fontSize:"30px", color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.75rem .938rem 1.25rem" }}>Achievements</MKTypography>
						</Grid>
						<Grid item md={7} >
							<MKTypography variant="body1" color="text" mb={3}>
								We are committed to delivering an education that cultivates well-rounded children, preparing them not only to seamlessly integrate into society but also to pursue further professional education. <br/><br/>
								Our mean score is 4.582. <br/>
								We take pride in our ability to send at least ten (10) students to public universities annually, while the remaining students continue their educational journey in various tertiary institutions. <br/><br/>
								We participate and compete favorably in music (Regional level), football, netball, volleyball and take part in scouting and other diverse pursuits.
							</MKTypography>
						</Grid>
					</Grid>
			</Box>
			<Footer content={footerRoutes} />
		</>
	);
}

export default AchievementsPg;
