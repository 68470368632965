import axios from "axios";
import errorHandler from "../helpers/errorHandler";

async function getTags() {
  try {
    // const { data } = await axios({ url: "/api/tags" });
    // console.dir("https://ekambulihighschool.sc.ke/api/blog/tags")
    const { data } = await axios({ url: "/api/tags" });
    // console.dir(data)
    
    return data;
  } catch (error) {
    errorHandler(error);
  }
}

export default getTags;
