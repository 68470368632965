/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Typography from "@mui/material/Typography";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
// import DefaultFooter from "layouts/sections/page-sections/page-footer";
// import {Spacer} from "components/spacer";

// // About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/class-20240612-1400x600.jpg";
import {Helmet} from "react-helmet";
import {useLocation} from 'react-router-dom';

function OverviewPg(props) {
	const loc = useLocation();
	console.dir(props)
	console.dir(loc)
	return (
		<>
			<Helmet>
				<meta name="description" content="Our approach." />
				<link rel="canonical" href={loc.pathname} />
			</Helmet>
			<DefaultNavbar
				routes={routes}

				// transparent
				// light
				relative
				sticky
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.6),
						rgba(gradients.dark.state, 0.6)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
				}}
			>
				{/* <Container> */}
					<Grid
						container
						item
						xs={12}
						lg={8}
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
						sx={{ mx: "auto", textAlign: "center" }}
					>
						<div className="text-[color:white] text-3xl md:text-4l text-center text-bold ">
							Educational excellence in Western Kenya
						</div>
					</Grid>
				{/* </Container> */}
			</MKBox>
			<Card
				sx={{
					p: 2,
					// mx: { xs: 2, lg: 3 },
					mt: -8,
					mb: 4,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
				}}
			>
				{/* <Container> */}
				<Grid container spacing={2} >
					<Grid item md={4} mt={-5}>
						{/* <MKTypography variant="h2" sx={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.75rem .938rem 1.25rem" }}>Overview</MKTypography> */}
						<div className="text-[color:white] text-3xl bg-sch_color rounded-lg px-3 py-1">Overview</div>
					</Grid>
					<Grid item md={8}>
						{/* <MKTypography variant="body1" mb={3}> */}
						<div className="font-semibold text-xl tracking-tighter mb-7">
							Ekambuli Secondary School follows the 8-4-4 Curriculum, leveraging the unique opportunities provided by our environment and location in Kenya. 
							We adhere to the Teachers Service Commission (TSC) syllabus in all subject areas.
						</div>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Educational Technology</div>
						<div className="text-lg mb-5">
							Our use of educational technology is currently limited.
						</div>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Teachers</div>
						<div className="text-lg mb-5">
							We have a dedicated team of twenty (20) TSC teachers and five (5) teachers employed by the Board of Management (BOM).
						</div>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Support staff</div>
						<div className="text-lg mb-5">
							We have 30 well-trained and dedicated support staff.
						</div>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Facilities</div>
						<div className="text-lg mb-5">
							Our facilities include a library, classrooms, boarding, dining, kitchen, computer lab, science lab, school bus, electricity, piped water, co-curricular activities, and a 20-acre farm. All facilities are secured by a fence and a gate.
						</div>
						<div className="text-sch_color1 font-semibold text-xl tracking-tighter">Supportive Community and Partners</div>
						<div className="text-lg mb-5">
							We receive dedicated support from parents/guardians, local MP Hon. Aseka, Khwisero NGCDF, and the community.
						</div>
					</Grid>
				</Grid>
				{/* </Container> */}
			</Card>
		<Footer content={footerRoutes} />
		</>
	);
}

export default OverviewPg;