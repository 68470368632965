import { useState } from "react";
import AuthPageContainer from "../components/AuthPageContainer";
import LoginForm from "../components/LoginForm";
import footerRoutes from "footer.routes";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import routes from "routes";

function Login() {
	const [errorMessage, setErrorMessage] = useState();

	const handleError = (error) => {
		console.dir(error)
		setErrorMessage(error);
	};

	return (
		<>
			<DefaultNavbar
				routes={routes}
				brand="Ekambuli High School"
				// action={{
				// 	type: "external",
				// 	route: "https://www.creative-tim.com/product/material-kit-react",
				// 	label: "free download1",
				// 	color: "info",
				// }}
				// transparent
				relative
				sticky
			/>
			<AuthPageContainer
				error={errorMessage}
				path="/register"
				text="Need an account?"
				title="Sign in"
			>
				<LoginForm onError={handleError} />
			</AuthPageContainer>
			<Footer content={footerRoutes} />
		</>
	);
}

export default Login;
