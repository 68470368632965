import React from "react";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ArticleMeta from "../../components/ArticleMeta";
// import ArticlesButtons from "../../components/ArticlesButtons";
import ArticleTags from "../../components/ArticleTags";
import BannerContainer from "../../components/BannerContainer";
import { useAuth } from "../../context/AuthContext";
import getArticle from "../../services/getArticle";
import {Spinner} from "components/spinner"
import * as mdxBundler from 'mdx-bundler/client/index.js'
import 'assets/css/mdx.css'

// import useArticleMdx from "../../hooks/useArticleMdx";

// import 'assets/css/mdx.css';
// import { responsiveFontSizes } from "@mui/material";

const Article = () => {
	const { state } = useLocation();
	const [article, setArticle] = useState(state || {});
	const [loading, setLoading] = useState(false);
	const { tagList, createdAt, author } = article || {};
	const { headers, isAuth } = useAuth();
	const navigate = useNavigate();
	const { slug } = useParams();

	// const [mdx, setMdx] = useState({});
	// const [loading, setLoading] = useState(true);
	
    // const renderers = {
    //     //This custom renderer changes how images are rendered
    //     //we use it to constrain the max width of an image to its container
    //     image: ({
    //         alt,
    //         src,
    //         title,
    //     }) => (
    //         <img 
    //             alt={alt} 
    //             src={src} 
    //             title={title} 
    //             style={{ maxWidth: 10 }}  />
    //     ),
    // };
	// console.log(state)
	// useEffect(() => {
	// 	getBodyMdx({ slug, headers })
	// 		.then((resp) => {
	// 			console.dir(resp)
	// 			setMdx(resp)
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		})
	// 		.finally(() => setLoading(false));
			
	// 	console.dir(mdx)
	// }, []);
	
	useEffect(() => {
		if (state) {
			setLoading(false)
			return;
		}

		getArticle({ slug, headers })
		.then(setArticle)
		.catch((error) => {
			console.error(error);
			navigate("/not-found", { replace: true });
		});
	}, [isAuth, slug, headers, state, navigate]);
	let Komponent = null;
	// const { Komponent, frontmatter, loading } = useArticleMdx({ slug, headers, });
	// console.dir(state.mdx)
	console.dir(state.mdx?.code)
	if(state.mdx?.code && state.mdx.code!=="") {
		console.dir(state.mdx.code)
		const Kcomponent = state.mdx ? mdxBundler.getMDXComponent(state.mdx.code) : null;
		Komponent = <Kcomponent key={slug} />
	}
	const {frontmatter} = state.mdx || {};
	console.dir(Komponent)
	if(loading || loading===undefined) {
		return (
			<div className="article-page">
				<Spinner size={16} showSpinner={loading || loading===undefined} />
			</div>
		)
	}
	// console.dir("loading:"+loading)
	// console.dir(Komponent)
	return	( 
		loading ? (
			<div className="article-preview">
				<Spinner size={16} showSpinner={loading} />
			</div>
		) : ( <>
			<div className="article-page">
				<BannerContainer>
					<h1>{frontmatter?.title || state.title}</h1>
					<ArticleMeta author={author} escapeHtml={false} createdAt={frontmatter?.date || state.createdAt}>
						{/* <ArticlesButtons readTime={state.mdx?.readTime} article={article} setArticle={setArticle} /> */}
					</ArticleMeta>
				</BannerContainer>
									{/* style={{border: 'solid', borderRadius: 15,  marginTop: 50, width: '100%'}} */}
				<div className="container page">
					<div className="row article-content1">
						<div className="col-md-12 text-xl">
							{/* {body && <Markdown options={{ forceBlock: true }}>{body}</Markdown>} */}
							{Komponent ? (
								Komponent
							) : (
								<Markdown options={{ forceBlock: true }}>{state.body}</Markdown>
							)
							}
							<ArticleTags tagList={tagList} />
						</div>
					</div>
					<hr />
					<div className="article-actions">
						<ArticleMeta author={author} createdAt={createdAt}>
							{/* <ArticlesButtons article={article} setArticle={setArticle} /> */}
						</ArticleMeta>
					</div>
					<Outlet />
				</div>
			</div>
			</>
		)
	);
}

export default Article;
