import { useAuth } from "../../context/AuthContext";
import { useFeedContext } from "../../context/FeedContext";
import FeedNavLink from "./FeedNavLink";
// import Navbar from "../Navbar";
import Grid from "@mui/material/Grid";

function FeedToggler() {
  const { isAuth } = useAuth();
  const { tabName, tagName } = useFeedContext();

  return (
    <Grid container className="feed-toggle">
			<Grid item xs={isAuth ? 10 : 10.5} sm={isAuth ? 10.5 : 11}>
        {/* <div className="feed-toggle"> */}
        <ul className="nav nav-pills outline-active">
          {isAuth && <FeedNavLink name="feed" text="Your Feed" />}

          <FeedNavLink name="global" text="School Feed" />

          {tabName === "tag" && <FeedNavLink icon name="tag" text={tagName} />}  
        </ul>
        {/* </div> */}
      </Grid>
      <Grid item pt={2}>
        {/* <Navbar /> */}
      </Grid>
    </Grid>
  );
}

export default FeedToggler;
