import axios from "axios";
import errorHandler from "../helpers/errorHandler";

async function userLogin({ email, password }) {
	try {
		console.dir("login")
		const { data } = await axios({
			data: JSON.stringify({ user: { email, password } }),
			headers : {"Content-Type":"application/json", },
			method: "POST",
			url: "/api/users/login",
		});
		console.dir(data)
		
		const { user } = data;
		if(user) {
			const headers = { Authorization: `Token ${user.token}` };

			const loggedIn = { headers, isAuth: true, loggedUser: user };

			localStorage.setItem("loggedUser", JSON.stringify(loggedIn));

			return loggedIn;
		} else {
			return;
		}
	} catch (error) {
		errorHandler(error);
	}
}

export default userLogin;
