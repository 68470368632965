import axios from "axios";
import errorHandler from "../helpers/errorHandler";

async function getUser({ headers }) {
	try {
		console.dir("/api/users")
		const { data={} } = await axios({ headers, url: "/api/user" }) || {};
		console.dir(data)
		return data?.user;
	} catch (error) {
		errorHandler(error);
	}
}

export default getUser;
