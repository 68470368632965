/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* States Grid React - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import DefaultNavbar from "layouts/sections/page-sections/Navbars/DefaultNavbar";
import Footer from "layouts/sections/page-sections/page-footer/sections/Footer";

// import {Grid} from "components/grid";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import bgImage from "assets/images/drama-20240612.jpg";
import {Helmet} from "react-helmet";
import {useLocation} from 'react-router-dom';

const ApproachPg = (props) => {
	const loc = useLocation();
	console.dir(props)
	return (
		<>
            <Helmet>
				<meta name="description" content="Our approach." />
                <link rel="canonical" href={loc.pathname} />
            </Helmet>
			<DefaultNavbar
				routes={routes}
				sticky
				relative
			/>
			<MKBox variant="h1"
				minHeight="75vh"
				width="100%"
				p={2}
				mt={-8}
				mb={4}
				sx={{
					backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
						`${linearGradient(
						rgba(gradients.dark.main, 0.1),
						rgba(gradients.dark.state, 0.1)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					display: "grid",
					placeItems: "center",
				}}
			>
				<div className="text-[color:white] text-3xl md:text-4l text-center text-bold "
					// variant="h1"
					// color="white"
					// sx={({ breakpoints, typography: { size } }) => ({
					// 	[breakpoints.down("md")]: {
					// 		fontSize: size["3xl"],
					// 	},
					// })}
				>
					For best learning outcomes
				</div>
			</MKBox>
			<Box p={2} mb={4} sx={{ maxWidth: '100%', flexGrow: 1, mb:"10px" }}>
				<Grid container spacing={2} >
					<Grid item md={4} mt={-9}>
						<MKTypography variant="h2" sx={{color:"#fff", backgroundColor:"#003da5", borderRadius: "7px!important", padding: "0.75rem .938rem 1.25rem" }}>Our Approach</MKTypography>
					</Grid>
					<Grid item md={8}>
						<MKTypography variant="body1" color="text" mb={3}>
						{/* <MKTypography variant="div" mb={3} sx={{color:"#212529", fontWeight:"400", fontFamily: "Arial Regular"}}> */}
							We firmly believe that the best learning outcomes are achieved through intrinsic motivation and self-drive. 
							We encourage students to join the boarding section for more teacher interaction. 
							We have engaged a team of experienced examiners, implemented the use of examination cards, supported our teachers to train as examiners in various subjects and launched a thorough revision strategy, exposing student to past papers and mock exams from top schools. 
						</MKTypography>
						<MKTypography variant="h3">Our Moto</MKTypography>
						<MKTypography variant="body1" color="text" mb={3}>
							Effort Breeds Success.
						</MKTypography>
						<MKTypography variant="h3">Our Vision</MKTypography>
						<MKTypography variant="body1" color="text" mb={3}>
							To be a Center of Exellence.
						</MKTypography>
						<MKTypography variant="h3">Our Mission</MKTypography>
						<MKTypography variant="body1" color="text" mb={3}>
							To educate and nurture reliable members of society. <br/><br/>
							We offer both the 8-4-4 and CBS education systems. <br/><br/>
							Beyond academics, our school shapes our students through a variety of co-curricular activities. <br/>
							We participate and compete at a high level in music (at the regional level), football, netball, volleyball, scouting, and more.<br/><br/>
							We are proud of our dedicated team of teaching and non-teaching staff who work tirelessly to implement all school-planned activities. <br/><br/>
							Guided by the principle &quot;Do unto others...,&quot; we believe that what we do for ourselves dies with us, but what we do for others endures even after we are gone. 
							This belief drives our commitment to our students and their education.
						</MKTypography>

					</Grid>
				</Grid>
			</Box>
			<Footer content={footerRoutes} />
		</>
	);
}

export default ApproachPg;
