/*
=========================================================
* States Grid - v1.1.0
=========================================================

* Copyright 2024

Coded by KanukPt

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "layouts/sections/components/Cards/TeamCards/HorizontalTeamCard";

// Images
// import team2 from "assets/images/smiley-ekass.jpg";
import pamela from "assets/images/friends/pamela-a.png";

function Team() {
	return (
		<MKBox
			component="section"
			variant="gradient"
			bgColor="dark"
			position="relative"
			py={6}
			px={{ xs: 2, lg: 0 }}
			mx={-2}
		>
			<Container>
				<Grid container px={2}>
					<Grid item xs={12} sx={{ mb: 6 }}>
						<MKTypography variant="h2" color="white">
							Friends of the school
						</MKTypography>
						<MKTypography variant="body1" color="white" opacity={0.8}>
							We value friends of our school.
						</MKTypography>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={6}>
						<MKBox mb={1}>
							<HorizontalTeamCard
								image={pamela}
								name="Pamela Ambundo"
								position={{ color: "info", label: "Friend Of The School" }}
								description=""
							/>
						</MKBox>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Team;
